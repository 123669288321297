<template>
  <div>
    <base-header class="pt-5 pt-md-8 bg-gradient-orange"></base-header>

    <b-container fluid class="mt--7">
      <!--Tables-->
      <b-row class="mt-5">
        <b-col xl="12" class="mb-5 mb-xl-0">
          <SummaryReportTable/>
        </b-col>
      </b-row>
      <!--End tables-->
    </b-container>
  </div>
</template>

<script>
import SummaryReportTable from "./Dashboard/SummaryReportTable"
export default {
  name: "SummaryReport",
  components :{
      SummaryReportTable
  }
};
</script>

<style>
.el-table .cell{
  padding-left: 0px;
  padding-right: 0px;
}
</style>